export default {
  computed: {
    paginationPageSize () {
      return this.paginationSize
    },
    totalPages () {
      if (this[this.gridName]) return Math.ceil(this[this.gridName].total / this.paginationSize) || 1
      return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.gridApi.paginationGoToPage(val - 1)
      }
    },
    gridAll(){
      if (this[this.gridName]) return this[this.gridName]
      return this.grid
    }
  },
  data: () => {
    return {
      paginationSize: 10,
      gridName: null,
      gridApi: null,
      grid: {
        data: [],
        total: 0
      },
    }
  },
  methods: {
    paginationSetSize(size) {
      this.paginationSize = size
      if (this.gridApi) {
        this.gridApi.paginationSetPageSize(size)
        this.currentPage = 1
      }
    },
    paginationSetPageSize(size){
      this.paginationSetSize(size)
    },
    setGrid(grid) {
      this.gridName = grid
    },
    getColumnSize(size) {
      return ((window.innerWidth/100) * size)
    }
  },
  created() {
    this.setGrid('allData')
  }
}
